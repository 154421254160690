body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.map {
  height: 100%;
}
/* Optional: Makes the sample page fill the window. */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #F7F8FE;
}

a, u {
  text-decoration: none;
  color: #000;
}

.pac-container {
  z-index: 10000 !important;
}

#login-backgound {
  background-image: url(assets/map.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-attachment: fixed;
}